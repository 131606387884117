.App_Header {
  background-color: var(--body);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--fnt-sz-lg);
  color: var(--text-primary);
  font-weight: var(--fnt-wg-lg);
}